import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlsService {
  mainSheetUrl = "https://script.google.com/macros/s/AKfycbwjCu6Z_xo7vG0MwwS4Gj5VkGEVSvVnBXo2uFs77WZM5XdC8SHAebsYzUFCxMBVMRxCVA/exec"

  constructor() {
  }
}
