import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 
  title = 'Welcome to Clarity!'

  constructor(private authService:AuthService){}

  loginWithGoogle(){
    this.authService.loginWithGoogle();
  }
}
