import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { navItemsForSuperAdmin } from '../../../_navForSuperAdmin';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{


  public sidebarMinimized = false;
  public navItemsForSuperAdmin = navItemsForSuperAdmin;

  isSuperAdmin = true


  currentUserKey: any
  currentUserEmail: any

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public http: HttpClient,
    ) {}

  ngOnInit(): void {
    this.currentUserKey = sessionStorage.getItem('userKey')
    this.currentUserEmail = sessionStorage.getItem('user')
    this.isSuperAdmin = false
  }

  async SignOut(){
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    this.router.navigate(['/']);
  }
}
