import { INavData } from '@coreui/angular';

export const navItemsForSuperAdmin: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
  },
  {
    name: 'Submitted',
    url: '/base/all_submissions'
  },
  {
    name: 'Rejected',
    url: '/base/all_rejections'
  },
  {
    name: 'Not Submitted',
    url: '/base/not_submitted'
  },
  {
    name: 'All Interns',
    url: '/base/all_interns'
  }
];
