import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseUrlsService } from '../../../services/baseUrlService/base-urls.service';
import { PassDataFromOneComponentTootherService } from '../../../services/passDataFromOneComponentToother/pass-data-from-one-component-toother.service';

@Component({
  selector: 'app-rejected-interns',
  templateUrl: './rejected-interns.component.html',
  styleUrls: ['./rejected-interns.component.scss']
})
export class RejectedInternsComponent implements OnInit {

  readyForNextStep: boolean = false
  showLoader: boolean

  allRejections: any = []
  index = 0;

  constructor(
    private passDataService: PassDataFromOneComponentTootherService,
    private router: Router,
    private baseurlService: BaseUrlsService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.showLoader = false
    this.readyForNextStep = !this.readyForNextStep
    this.http.get(`${this.baseurlService.mainSheetUrl}`, {params: {
      "name": "AutomatedInternshipProcess"
    }}).subscribe((result:any) => {
    this.http.get<any>(`${result.data}`, {params: {
      "uid": sessionStorage.getItem("userKey"),
      "email": sessionStorage.getItem("user"),
      "func": 15
    }}).subscribe(result => {
      this.showLoader = true
      this.readyForNextStep = !this.readyForNextStep
      result.data.forEach((singleIntern: any) => {
        this.allRejections.push(singleIntern)
      })
    })
  })
  }

  getInternWiseTaskDetails(uid: any) {
    this.passDataService.internUid = uid
    this.router.navigate(['/base/intern_wise_task_details'])
  }

}
