import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { userAuth } from './userAuth';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: userAuth;

  constructor(
    private fireAuth: AngularFireAuth,
    public router: Router,
    public afAuth: AngularFireAuth,

  ) {
    this.fireAuth.authState.subscribe(user => {
      this.user = user;
    });
  }

  isLoggedIn(): boolean {
    const user = sessionStorage.getItem('user');
    return (user !== null) ? true : false
  }

  async loginWithGoogle() {
    await this.fireAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider()).then(
      res => {
        sessionStorage.setItem("user", res.user.email)
        sessionStorage.setItem("userKey", res.user.uid)
        let userType = sessionStorage.getItem('userType')
        this.router.navigate(['dashboard']);
      }).catch(err => {
        console.error('Error while sign in')
      })

  }
}