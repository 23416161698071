import { BaseUrlsService } from './../../../services/baseUrlService/base-urls.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-interns',
  templateUrl: './all-interns.component.html',
  styleUrls: ['./all-interns.component.scss']
})
export class AllInternsComponent implements OnInit {

  readyForNextStep: boolean = false
  showLoader: boolean
  index = 0;
  userEmail: any
  userKey: any
  allInterns: any = []

  internsOnMcq: any = []
  internsOnTasks: any = []
  internsCompleted: any = []
  totalInterns = 0
  onMcq = 0
  onTasks = 0
  completed = 0

  constructor(
    private http: HttpClient,
    private baseurlService: BaseUrlsService
  ) { }

  ngOnInit(): void {
    this.showLoader = false
    this.readyForNextStep = !this.readyForNextStep
    this.userEmail = sessionStorage.getItem('user')
    this.userKey = sessionStorage.getItem('userkey')
    this.http.get(`${this.baseurlService.mainSheetUrl}`, {params: {
      "name": "AutomatedInternshipProcess"
    }}).subscribe((result: any) => {
      this.http.get<any>(`${result.data}`, {params: {
        "uid": sessionStorage.getItem("userKey"),
        "email": sessionStorage.getItem("user"),
        "func": "14"
      }}).subscribe(result => {
        this.showLoader = true
        this.readyForNextStep = !this.readyForNextStep
        result.data.forEach((singleResult: any) => {
          this.totalInterns = result.data.length
          let updatedResult = singleResult
          updatedResult.deadline = new Date(singleResult.deadline).toLocaleDateString()
          let dateInEpoch = new Date(singleResult.doj).getTime()
          updatedResult.doj = new Date(dateInEpoch+(5*60*60*1000)+(30*60*1000)).toLocaleDateString()
          this.allInterns.push(updatedResult)
  
          if (updatedResult.currentTask == "MCQ") {
            this.internsOnMcq.push(updatedResult)
            this.onMcq = this.internsOnMcq.length
          } else if (updatedResult.currentTask == "Internship Complete" || updatedResult.currentTask == "Certificate") {
            this.internsCompleted.push(updatedResult)
            this.completed = this.internsCompleted.length
          } else if (updatedResult.currentTask !== "Index"){
            this.internsOnTasks.push(updatedResult)
            this.onTasks = this.internsOnTasks.length
          }
        })
      })
    })
  }

}
