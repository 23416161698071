import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PassDataFromOneComponentTootherService {

  internUid: any

  constructor() { }

}
