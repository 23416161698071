import { HttpClient } from '@angular/common/http';
import { BaseUrlsService } from './../../../services/baseUrlService/base-urls.service';
import { Router } from '@angular/router';
import { PassDataFromOneComponentTootherService } from './../../../services/passDataFromOneComponentToother/pass-data-from-one-component-toother.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-submissions',
  templateUrl: './all-submissions.component.html',
  styleUrls: ['./all-submissions.component.scss']
})
export class AllSubmissionsComponent implements OnInit {

  readyForNextStep: boolean = false
  showLoader: boolean

  allSubmissions: any = []
  index = 0;
  
  constructor(
    private passDataService: PassDataFromOneComponentTootherService,
    private router: Router,
    private baseurlService: BaseUrlsService,
    private http: HttpClient
    ) { }

  ngOnInit(): void {
    this.showLoader = false
    this.readyForNextStep = !this.readyForNextStep
    this.http.get(`${this.baseurlService.mainSheetUrl}`, {params: {
      "name": "AutomatedInternshipProcess"
    }}).subscribe((result:any) => {
    this.http.get<any>(`${result.data}`, {params: {
      "uid": sessionStorage.getItem("userKey"),
      "email": sessionStorage.getItem("user")
    }}).subscribe(result => {
      this.showLoader = true
      this.readyForNextStep = !this.readyForNextStep
      result.data.forEach((singleIntern: any) => {
        this.allSubmissions.push(singleIntern)
      })
    })
  })
  }

  getInternWiseTaskDetails(uid: any) {
    this.passDataService.internUid = uid
    this.router.navigate(['/base/intern_wise_task_details'])
  }

}
