import { Router } from '@angular/router';
import { BaseUrlsService } from './../../../services/baseUrlService/base-urls.service';
import { HttpClient } from '@angular/common/http';
import { PassDataFromOneComponentTootherService } from './../../../services/passDataFromOneComponentToother/pass-data-from-one-component-toother.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-this-intern-details',
  templateUrl: './this-intern-details.component.html',
  styleUrls: ['./this-intern-details.component.scss']
})
export class ThisInternDetailsComponent implements OnInit {

  managerEmailID: string
  readyForNextStep: boolean = false
  showLoader: boolean
  isButtonClicked: boolean

  name: string
  mobile: string
  email: string
  doj: string
  currentTask: string
  deadline: string
  remark: string
  score: string
  enterRemark: string
  enterScore: string
  submission: string
  status: string
  uid: string

  dateEntered: string

  constructor(
    private passDataService: PassDataFromOneComponentTootherService,
    private http: HttpClient,
    private baseUrlService: BaseUrlsService,
    private route: Router
    ) { }

  ngOnInit(): void {
    this.showLoader = false
    this.readyForNextStep = !this.readyForNextStep
    this.managerEmailID = sessionStorage.getItem("user")
    this.http.get(`${this.baseUrlService.mainSheetUrl}`, {params: {
      "name": "AutomatedInternshipProcess"
    }}).subscribe((result:any) => {
    this.http.get<any>(`${result.data}`, {params: {
      "uid": sessionStorage.getItem("userKey"),
      "email": sessionStorage.getItem("user"),
      "internUID": this.passDataService.internUid,
      "func": "10"
    }}).subscribe(result => {
      this.showLoader = true
      this.readyForNextStep = !this.readyForNextStep

      this.currentTask = result.data[0].currentTask
      this.deadline = new Date(result.data[0].deadline).toLocaleDateString()
      let dateInEpoch = new Date(result.data[0].doj).getTime()
      this.doj = new Date(dateInEpoch+(5*60*60*1000)+(30*60*1000)).toLocaleDateString()   
      this.email = result.data[0].email
      this.remark = result.data[0].remark
      this.mobile = result.data[0].mobile
      this.name = result.data[0].name
      this.score = result.data[0].score
      this.status = result.data[0].status
      this.submission = result.data[0].submission
      this.uid = result.data[0].uid
    })
  })
  }


  approveThisTask(){
    this.isButtonClicked = true
    if (this.dateEntered === undefined && this.enterRemark === undefined) {
      Swal.fire({
        title: "Error",
        text: 'Deadline is empty!',
        icon: 'error',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      })
      this.isButtonClicked = false
    } else if (this.enterRemark === undefined) {
      Swal.fire({
        title: "Error",
        text: 'Remark is empty!',
        icon: 'error',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      })
      this.isButtonClicked = false
    } else if (this.dateEntered !== undefined && this.enterRemark !== undefined) {
      this.http.get(`${this.baseUrlService.mainSheetUrl}`, {params: {
        "name": "AutomatedInternshipProcess"
      }}).subscribe((result:any) => {
      this.http.get<any>(`${result.data}`, {params: {
      "uid": sessionStorage.getItem("userKey"),
      "email": sessionStorage.getItem("user"),
      "internUID": this.uid,
      "deadline": (new Date(this.dateEntered).getTime() - (5*60*60*1000 + 30*60*1000) + (24*60*60*1000)),
      "score": this.enterScore,
      "remark": this.enterRemark,
      "func": "11"
    }}).subscribe(result => {
      if (result.data == "Task Approved - Deadline set for New Task") {
        Swal.fire({
          title: 'Success',
          text: 'Task Approved successfully!',
          icon: 'success',
          allowEscapeKey:false,
          allowOutsideClick: false,
          showConfirmButton: true
        }).then(result => {
          if (result.isConfirmed) {
            this.route.navigate(['/base/all_submissions'])
          }
        })
      }
    })
  })
    } else {
      console.log("invalid case")
    }
    
  }

  rejectThisTask(){
    this.isButtonClicked = true
    if (this.enterRemark === undefined) {
      Swal.fire({
        title: "Error",
        text: 'Remark is empty!',
        icon: 'error',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      })
      this.isButtonClicked = false
    } else if (this.dateEntered === undefined) {
      Swal.fire({
        title: "Error",
        text: 'Deadline is empty!',
        icon: 'error',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      })
      this.isButtonClicked = false
    } else if (this.dateEntered !== undefined && this.enterRemark !== undefined) {
      this.http.get(`${this.baseUrlService.mainSheetUrl}`, {params: {
        "name": "AutomatedInternshipProcess"
      }}).subscribe((result:any) => {
      this.http.get<any>(`${result.data}`, {params: {
      "uid": sessionStorage.getItem("userKey"),
      "email": sessionStorage.getItem("user"),
      "internUID": this.uid,
      "deadline": (new Date(this.dateEntered).getTime() - (5*60*60*1000 + 30*60*1000) + (24*60*60*1000)),
      "score": this.enterScore,
      "remark": this.enterRemark,
      "func": "12"
    }}).subscribe(result => {
        if (result.data == "Task Rejected - New Deadline set for Current Task") {
          Swal.fire({
            title: 'Success',
            text: 'Task rejected successfully!',
            icon: 'success',
            allowEscapeKey:false,
            allowOutsideClick: false,
            showConfirmButton: true
          }).then(result => {
            if (result.isConfirmed) {
              this.route.navigate(['/base/all_rejections'])
            }
          })
        }
    })
  })
    } else {
      console.log("invalid case")
    }
    
  }

}
