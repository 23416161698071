import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import {LocationStrategy, HashLocationStrategy, DatePipe} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
// Import containers
import { AppAsideModule, AppBreadcrumbModule, AppHeaderModule, AppFooterModule, AppSidebarModule } from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';

 // Firebase integration:
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from '@angular/fire/storage';

// Additional components
import { DefaultLayoutComponent } from './containers/containers/default-layout';
const APP_CONTAINERS = [DefaultLayoutComponent];

//services
import { AuthService } from './services/auth/auth.service';
import { AllSubmissionsComponent } from './views/components/all-submissions/all-submissions.component';
import { ThisInternDetailsComponent } from './views/components/this-intern-details/this-intern-details.component';
import { AllInternsComponent } from './views/components/all-interns/all-interns.component';
import { RejectedInternsComponent } from './views/components/rejected-interns/rejected-interns.component';
import { NotSubmittedComponent } from './views/components/not-submitted/not-submitted.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    DataTablesModule,
    IconModule,
    IconSetModule.forRoot(),
    NgbModule,
    // firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    //Google Cloud Storage
  ],
  declarations: [

    AppComponent,
    ...APP_CONTAINERS,
    AllSubmissionsComponent,
    ThisInternDetailsComponent,
    AllInternsComponent,
    RejectedInternsComponent,
    NotSubmittedComponent,
  ],
  providers: [
    AuthService,
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy},
    IconSetService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
