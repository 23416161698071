export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyCZrjqPM0plSTNuusEZFilz5ECKAANHVLk",
        authDomain: "heal-weheal.firebaseapp.com",
        databaseURL: "https://heal-weheal.firebaseio.com",
        projectId: "heal-weheal",
        storageBucket: "heal-weheal-user-profile-media",
        messagingSenderId: "356373199258",
        appId: "1:356373199258:web:6d128b15ce7ebdc4c0a223",
        measurementId: "G-MCHN3VMLP2"


        // apiKey: "AIzaSyAKIAVNvYO2i1-1ZY9fc8dTRCz28jj0d8s",
        // authDomain: "weheal-debug.firebaseapp.com",
        // databaseURL: "https://weheal-debug-default-rtdb.firebaseio.com",
        // projectId: "weheal-debug",
        // storageBucket: "weheal-debug-user-profile-media",
        // messagingSenderId: "508063414821",
        // appId: "1:508063414821:web:1b77cc04d955e7787a5dcc",
        // measurementId: "G-CHJXYYDCF3"
    },
  };